import { useState, SyntheticEvent } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Grid, Tab, Tabs, Typography } from '@mui/material';

import MainCard from 'ui-component/cards/MainCard';
import UnitFormTabs from './UnitFormTabs';

import { tabStyle } from 'views/properties/utils';
import { tabsOption } from './utils';

import color from 'assets/scss/_themes-vars.module.scss';
import './style.scss';
import { a11yProps } from 'ui-component/a11yProps/a11yProps';
import { IsAddPropertyUnitLoadingSelector, selectedPropertySelector, SelectedPropertyUnitSelector } from 'store/properties/selector';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { ClientsReducer, ClientsReducerName } from 'store/clients/slice';
import { ClientsSaga, ClientsSagaName } from 'store/clients/saga';
import { UserReducer, UserReducerName } from 'store/users/slice';
import { UserSaga, UserSagaName } from 'store/users/saga';
import SubHeaderComponent from 'ui-component/SubHeaderComponent';
import Loader from 'ui-component/Loader';
import { PropertiesReducer, PropertiesReducerName } from 'store/properties/slice';
import { PropertiesSaga, PropertiesSagaName } from 'store/properties/saga';
import { PropertyDetailsReducer, PropertyDetailsReducerName } from 'store/propertyDetails/slice';
import { PropertyDetailsSaga, PropertyDetailsSagaName } from 'store/propertyDetails/saga';
import { AgenciesSaga, AgenciesSagaName } from 'store/agencies/saga';
import { AgenciesReducer, AgenciesReducerName } from 'store/agencies/slice';
import routeConstant from 'routes/routeConstant';

const ModalHeader = () => {
    const theme = useTheme();

    useInjectReducer({
        reducer: ClientsReducer,
        key: ClientsReducerName
    });

    useInjectSaga({
        saga: ClientsSaga,
        key: ClientsSagaName
    });
    useInjectReducer({
        reducer: UserReducer,
        key: UserReducerName
    });

    useInjectSaga({
        saga: UserSaga,
        key: UserSagaName
    });

    useInjectReducer({
        reducer: PropertiesReducer,
        key: PropertiesReducerName
    });
    useInjectSaga({
        saga: PropertiesSaga,
        key: PropertiesSagaName
    });
    useInjectReducer({
        reducer: PropertyDetailsReducer,
        key: PropertyDetailsReducerName
    });
    useInjectSaga({
        saga: PropertyDetailsSaga,
        key: PropertyDetailsSagaName
    });

    useInjectSaga({
        saga: AgenciesSaga,
        key: AgenciesSagaName
    });

    useInjectReducer({
        reducer: AgenciesReducer,
        key: AgenciesReducerName
    });

    const [value, setValue] = useState<number>(0);
    const { id } = useParams();
    const propertyDetails = useSelector(selectedPropertySelector);
    const selectedPropertyUnit = useSelector(SelectedPropertyUnitSelector);
    const isLoading = useSelector(IsAddPropertyUnitLoadingSelector);
    const handleChange = (_: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    if (isLoading) return <Loader backdropOpen />;
    return (
        <>
            <SubHeaderComponent
                module="Properties"
                customPathEndPointName={propertyDetails?.property_name}
                title="Unit Profile"
                homeIcon
                subModule={['Units', selectedPropertyUnit?._id ? selectedPropertyUnit?.unit_name : 'Unit', id ? 'Edit' : 'Add']}
                dynamicLink={routeConstant.properties}
                customEndPointlink={`${routeConstant.propertiesProfile}/${propertyDetails?._id}`}
            />
            <MainCard sx={{ background: 'rgb(248, 245, 242)' }} className="property-forms-header-main-card">
                <Grid container paddingRight={0}>
                    <Grid sx={{ background: 'white', width: '100%', overflow: 'hidden' }}>
                        <Grid padding="20px 20px 10px 20px" className="property-forms-header" item display={'flex'}>
                            <Grid width={'100%'} className="property-forms-tab-container" display="flex" justifyContent={'space-between'}>
                                <Grid item className="new-property-container" display="flex" alignItems="center">
                                    <Typography sx={{ fontSize: { xs: '1rem', sm: '1.25rem' }, fontWeight: 600, color: color.grey1000 }}>
                                        Unit {selectedPropertyUnit?.unit_name} Settings
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid
                                display={'flex'}
                                alignItems={'center'}
                                flexWrap={'wrap'}
                                justifyContent={'end'}
                                className="property-forms-helper-button property-center-div"
                            >
                                <Grid
                                    display={'flex'}
                                    alignItems={'center'}
                                    flexWrap={'wrap'}
                                    width={'100%'}
                                    className="property-forms-upper-tabs"
                                    gap={'15px'}
                                >
                                    <Tabs
                                        value={value}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        onChange={handleChange}
                                        variant="scrollable"
                                        sx={{ ...tabStyle(theme), marginBottom: '0px !important' }}
                                    >
                                        {tabsOption.map((tab, index) => (
                                            <Tab
                                                className="update-sub-header-icon-button"
                                                key={index}
                                                component={Link}
                                                to="#"
                                                icon={tab.icon}
                                                label={tab.label}
                                                {...a11yProps(index)}
                                            />
                                        ))}
                                    </Tabs>
                                </Grid>
                            </Grid>
                        </Grid>
                        <UnitFormTabs value={value} />
                    </Grid>
                </Grid>
            </MainCard>
        </>
    );
};

export default ModalHeader;
